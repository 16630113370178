<template>
  <div id="videoplayer_container">
    <div v-if="!emptyPlaylist" v-show="!showingLeaderboard">
      <!-- La playlist es correcta y tiene videos -->
      <VideoDouob v-for="item in playlist" :key="item.id" :ref="item.id" :src="item.video.url"
      @ended="onVideoPlayerEnded" :muted="true" />
    </div>
    
    <LeaderBoard v-if="renderLeaderboard" v-show="showingLeaderboard"/>
  </div>

</template>

<script>
import VideoDouob from '../VideoDouob.vue';
import LeaderBoard from './leaderBoard.vue'

export default {
  components: {
    VideoDouob,
    LeaderBoard
  },
  data() {
    return {
      selected: {},
      showingLeaderboard: false,
      renderLeaderboard: true
    }
  },
  props: {
    playlist: {
      type: Array,
      required: true,
      played: false,
    },
    leaderboard: {
      type: Object,
      required: false,
      default: undefined,
    }
  },
  methods: {
    Pause(){
      this.videoplayer.pause()
    },
    ResumePlayback(){
      this.videoplayer.play()
    },
    rerenderLeadeboard(){
      console.log('rerender')
      this.renderLeaderboard = false,

      this.$nextTick(() => {
        this.renderLeaderboard = true
      })
    },
    onVideoPlayerEnded() {
      // Next video
      // const arrToLook = this.standbyMode ? this.options.standByVideos : this.options.videos; // con esto la playlist sigue al proximo video
      let currElementIndex = this.playlist.indexOf(this.selected);
      const nextIndex = (currElementIndex + 1) % this.playlist.length;
      this.selected = null;
      this.selected = this.playlist[nextIndex]
      this.$emit('videoEnded')
    },
  },
  computed: {
    videoplayer() {
      return this.$refs[this.selected.id][0]
    },
    emptyPlaylist() {
      if(this.playlist.length == 0){
        return true;
      }
      if(this.playlist.some(x => !x.video)){
        return true;
      }
      return false;
    },
  },
  watch: {
    // volume(vol) {
    //   // if (this.player) {
    //   //   this.player.volume = vol / 100;
    //   // }
    // }
    selected(selected, prevVideo) {
      if(this.emptyPlaylist) {
        return null;
      }
      
      if (prevVideo.id) {
        this.$refs[prevVideo.id][0].stop()
      }

      if(prevVideo.id && this.leaderboard?.show && this.$store.state.space.players.length == 0) {

        this.showingLeaderboard = true

        setTimeout(() => {

          this.showingLeaderboard = false;
          this.videoplayer.play()

        },(this.leaderboard.duration || 10) * 1000)
        
      } else {
        
        this.videoplayer.play()
      }

    },
    showingLeaderboard(now){
      this.$store.commit('space/ShowLeaderboard',now)
    }
  },
  mounted() {
    if(this.emptyPlaylist) {
      console.log("No videos in playlist")
      return
    }
    this.selected = this.playlist[0]
    this.$refs
  },
}
</script>